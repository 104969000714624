:root {
  --red: #ef233c;
  --darkred: #c00424;
  --platinum: #e5e5e5;
  --black: #2b2d42;
  --white: #fff;
  --thumb: #edf2f4;
  --stroke:#bebebe;
  --mgreen:#50AA05;
--lgreen:#C8DD02;
--purple:#C11DAB;
--pink:#F04C98;
--lblack:#05131C;
--dbluer:#2E3091;
--lblue:#62B1E5;
--mblue:#0079C8;
--bblue:#D7EBFA;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font: 1em / 1.5em "Rubik", sans-serif;
  color: var(--black);
  background: var(--stroke);
  /* margin: 50px 0; */
}

#container {
  /* max-width: 1400px; */
  padding: 0 1rem;
  margin: 0 auto;
}

h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

#cards {
  display: flex;
  /* padding: 1.5rem 0; */
  list-style: none;
  height: 100vh;
  overflow-x: scroll;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
}

.card {
  display: flex;
  flex-direction: column;
  flex: 1 0 100vw;
  /* padding: 1.5rem; */
  background: var(--white);
  /* border-radius: 1rem; */
  /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 15%); */
  scroll-snap-align: start;
  transition: all 0.2s;
  align-items: center;
  text-align: center;
}

.card:not(:last-child) {
  margin-right: 1rem;
}

.card:hover {
  color: var(--white);
  background: var(--mgreen);
}

.card .card-title {
  font-size: 1.5rem;
}

.card .card-content {
  margin: 1.5rem 0;
  /* max-width: 85%; */
}

.card .card-link-wrapper {
  /* margin-top: auto; */
}

.card .card-link {
  display: inline-block;
  text-decoration: none;
  color: white;
  background: var(--red);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition: background 0.2s;
}

.card:hover .card-link {
  background: var(--mgreen);
}

.cards::-webkit-scrollbar {
  height: 1rem;
}

.cards::-webkit-scrollbar-thumb,
.cards::-webkit-scrollbar-track {
  border-radius: 6rem;
}

.cards::-webkit-scrollbar-thumb {
  background: var(--mgreen);
}

.cards::-webkit-scrollbar-track {
  background: var(--thumb);
}

.cards-item__card--image {
  border-radius: 50%;
}
/* end card */

/*
input[type='text'] {
  font: bold 1.5rem/1.5rem "Rubik", sans-serif;
  
  padding: 1rem;
  margin: 0 1rem;
  border: none;
  border-bottom: 0.2rem solid var(--mgreen);
  box-sizing: border-box;
  text-align: center;
  background: var(--stroke);
}
*/

@media (min-width: 500px) {
  .card {
    flex-basis: calc(50% - 1rem);
  }

  .card:not(:last-child) {
    margin-right: 1.5rem;
  }
}

@media (min-width: 700px) {
  .card {
    flex-basis: calc(calc(100% / 3) - 1.5rem);
  }

  .card:not(:last-child) {
    margin-right: 2rem;
  }
}

@media (min-width: 1100px) {
  .card {
    flex-basis: calc(25% - 2rem);
  }

  .card:not(:last-child) {
    margin-right: 3rem;
  }
}


/* FOOTER STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
  position: fixed;
  right: 0;
  bottom: 3rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  z-index: 1;
}

.page-footer a {
  display: flex;
  margin-left: 0.5rem;
}

div.card-link-wrapper > p {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width:17rem;
}
div.card-link-wrapper > p > a > img{
  object-position: right top;
  object-fit: none;
}
.active{background-color: red;}
.accordion {
  list-style: none;
}

.accordion_item {
  border-top: 1px solid #9f9f9f;
}

.button {
  font-size: 1rem;
  background-color: #008593;
  color: #fff;
  text-align: left;
  /* font-weight: 700; */
  display: flex;
  /* flex-wrap: wrap; */
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  border: none;
}

.control {
  font-size: 20px;
}

.answer {
  background-color: #f7f7f7;
  padding: 20px;
}

/* activate toggle */
.accordion_item.active .button {
  background-color: #105057;
}
.answer_wrapper {
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;
}
/* .answer_wrapper.open {
  height: auto;
} */
